import React, { useEffect, useState } from "react";
import { useRouter } from 'next/router'
import { motion } from 'framer-motion'
import { useSiteContext } from "../../context/appContext"
// import BaseHeader from "../master/BaseHeader";
// import BaseFooter from "../master/BaseFooter";
import styled from "styled-components";
import BaseMenu from "../master/BaseMenu";
import HeroSlider from "../base/HeroSlider";
// import client from "../../lib/graphql"
// import { GET_HERO_CONTENT } from '../../graphql/query' 
import Menus from "../base/Menus";
import CustomCursor from "../base/CustomCursor";
import dynamic from 'next/dynamic'

interface LayoutProps {
  children: React.ReactNode;
  heroSlider?: THeroSlider|null;
}

type TSlider = {
  title?: string,
  description?: string,
  is_video: boolean,
  color?: string,
  more_link: string,
  media?: {
    data: {
      attributes: {
        url: string,
        name: string
      }
    }
  },
  video_url?: string,
  invert: boolean
}

type THeroSlider = {
  play_speed: number,
  media_items: TSlider[]
}

const variants = {
  hidden: { opacity: 0, y: 100 },
  enter: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 100 },
}

const menuDatas = [ '/', '/3d-visuals', '/product-design', '/we-learn' ];

// const DynamicHeroSlider = dynamic(() => import('../base/HeroSlider'), {
//   ssr: false
// })


const DynamicFooter = dynamic(() => import('../master/BaseFooter'), {
  ssr: false
})

const DynamicHeader = dynamic(() => import('../master/BaseHeader'), {
  ssr: false
})


const Layout = ({ children, heroSlider }: LayoutProps) => {
  const { context, setContext } = useSiteContext();
  const [loaded, setLoaded] = useState<boolean>(false)
  const router = useRouter()
  const [ showHero, setShowHero ] = useState<boolean>(true);
  const [ activeSlide, setActiveSlide ] = useState(0);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let browser = window
      const handleScroll = () => {
        if (typeof window !== 'undefined') {
          const heroSlider = document.getElementById('hero')
          let _distance = 40

          if (heroSlider !== null) {
            _distance = heroSlider.clientHeight
          }

          if (window.pageYOffset > _distance && !context.isSticky) {
            setContext({
              ...context,
              isSticky: true,
            })

            document.body.classList.add('is-sticky')

          } else if (context.isSticky && window.pageYOffset <= _distance) {
            setContext({
              ...context,
              isSticky: false,
            })
            document.body.classList.remove('is-sticky')
          }
        }
      }

      browser.addEventListener('scroll', handleScroll)

      return () => {
        browser.removeEventListener('scroll', handleScroll)
      }
    }
  }, [context, setContext])

  // useEffect(() => {

  //   const fetchSiteInfo = async () => {
  //     const { data: { headerSlider } } = await client.query({
  //       query: GET_HERO_CONTENT,
  //     });
      
  //     if ( headerSlider.data ) {
  //       setHeroSlider(headerSlider.data.attributes.items)
  //     }
  //   }

  //   fetchSiteInfo()
  //   .catch(console.error);
    
  // },[])

  useEffect(() => {
   
    if ( router.pathname === '/impressum' || router.pathname === '/agb') {
      setShowHero(false)
    } else {
      setShowHero(true)
    }

    router.events.on("routeChangeStart", () => {
      setContext({
        ...context,
        isMenuOpen: false
      })
      document.body.removeAttribute("style");
    });

  }, [showHero, router, context, setContext])

  useEffect(() => {
    let _timout = setTimeout(() => {
      setLoaded(true)
    },1000)

    return () => {
      clearTimeout( _timout);
    };
    
  },[])

  useEffect(() => {
    const { pathname } = router;
    setActiveSlide(menuDatas.indexOf(pathname));

  },[router, activeSlide])

  return (
    <Page className="page">

      <DynamicHeader />
      <div id="hero-header" className={`section full-view ${!showHero ? 'hidden' : ''}`}>
        <div id="slider-wrap">
          { (heroSlider) && <>
              <HeroSlider 
                activeSlide={0}
                playSpeed={ heroSlider.play_speed}
                contents={ heroSlider.media_items}
              />
            </>
          }
        </div>
        <div id="menus" className='section grid spacing'>
          <Menus swipe variant='menus-body' scrollTop={false} activeSlide={activeSlide} />
        </div>
      </div>

      <motion.main
        initial="false"
        animate="enter"
        exit="exit"
        variants={variants}
        transition={{ duration: 1, type: 'linear' }}
      >
      {children}
      </motion.main>

      { showHero && <div className='section hidden-mobile grid'>
            <Menus swipe variant='menus-body' scrollTop={true} activeSlide={0} />
        </div> 
        }

      <DynamicFooter />

      { context.isMenuOpen && <BaseMenu /> }

      {loaded && <CustomCursor /> }
    </Page>
  )
}

export default Layout;


const Page = styled.div`
  margin: 0 auto;
  padding: 0;
  position: relative;
  height: auto;
  width: 100%;

  main {
    height: auto;
    width: 100%;
    aspect-ratio: 1;
  }

  #hero-header #menus {
    min-height: 100px;
  }

  #hero-header #slider-wrap {
    min-height: 934px;
  }

  .hidden {
    display: none;
  }

  @media screen and (min-width: ${({theme}) => theme.breakPoints.desktop}) {
    #hero-header #slider-wrap {
      min-height: 42.8vw;
    }
  }
`

