import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ReactPlayer from 'react-player/vimeo'
import SliderComponent from "./SliderWrap";
import { SwiperSlide } from 'swiper/react';
import Image from "next/image";
import Link from "next/link";

type TSlider = {
  title?: string,
  description?: string,
  is_video: boolean,
  color?: string,
  background_color?: string,
  more_link: string,
  media?: {
    data: {
      attributes: {
        url: string,
        name: string
      }
    }
  },
  video_url?: string,
  invert: boolean
}

type Props = {
  activeSlide: number,
  contents: TSlider[],
  playSpeed: number
}



const HeroSlider = ({ activeSlide = 0, contents, playSpeed } : Props ) => {

  const hexToRGB = (hex:string, alpha: number )=> {
    let r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

  const [hasWindow, setHasWindow] = useState(false)


  useEffect (() => {
    if (typeof window !== "undefined") {
      setHasWindow(true)
    }
  },[])


  const SlideImage = (content: TSlider, textColor: string, bgColor: string) => {

    if ( content.media && content.media.data) {
        const _src = `${process.env.NEXT_STRAPI_API_URL }${content.media.data.attributes.url}`;

        return ( <>
                <div className="hero_item__wrap" style={{ color: textColor, backgroundColor: bgColor }}>
                  <div className="hero_item__content">
                    { content.title && <h2 dangerouslySetInnerHTML={{__html: content.title }} ></h2>}
                    { content.description && <div className="hero_item__desc">{content.description}</div>}
                  </div>

                  {content.more_link && (
                    <div className="hero_item__more">
                      <Link href={content.more_link}>
                        <a>
                          <span style={{ color: textColor }}>more</span>
                          <svg viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.27539 0.613966L12.3379 4.67647L12.3379 5.81397L8.27539 9.87647L7.12164 8.73897L9.78664 6.05772L0.735389 6.05772L0.735389 4.43272L9.78664 4.43271L7.10539 1.75147L8.27539 0.613966Z" fill={textColor} />
                          </svg>
                        </a>
                      </Link>
                    </div>
                  )}

                  <div className="hero_item__picture">
                    <div className="hero_item__picture__wrap">
                      <Image
                        src={_src}
                        alt=""
                        layout="fill"
                        objectFit={'cover'}
                        // width={824}
                        // height={643}
                        priority
                        sizes={"100vw"}
                        placeholder="blur"
                        blurDataURL={`data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=`}
                      />
                    </div>
                  </div>
                </div>
          </>
        )

    }
  }

  const SlideVideo = (content: TSlider, isActive: boolean ) => {

    if ( content.media && content.media.data) {
      let _src = ''

      if (content.media.data && content.media.data.attributes.url !== '') {
        _src = `${process.env.NEXT_STRAPI_API_URL }${content.media.data.attributes.url}`
      }

      return (
        <div className="hero_item__wrap has-video">
          <div className="hero_item__picture">
            <div className="player-wrapper">
              { hasWindow && 
                <ReactPlayer 
                  className='react-player'
                  width="100%"
                  height="100%"
                  url={`${content.video_url}`}
                  loop
                  playing={isActive}
                  playsinline
                  playIcon={
                    <button className="btnPlay transition-all" type="button" title="play video" aria-label="play video">
                      <span><svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3.38 1.91608C3.19004 1.80523 2.97418 1.74646 2.75424 1.74571C2.5343 1.74497 2.31806 1.80227 2.12734 1.91182C1.93663 2.02138 1.7782 2.17932 1.66805 2.3697C1.5579 2.56007 1.49993 2.77613 1.5 2.99608V19.4841C1.49993 19.704 1.5579 19.9201 1.66805 20.1105C1.7782 20.3008 1.93663 20.4588 2.12734 20.5683C2.31806 20.6779 2.5343 20.7352 2.75424 20.7344C2.97418 20.7337 3.19004 20.6749 3.38 20.5641L18.078 11.9741C18.2062 11.8992 18.3126 11.792 18.3865 11.6632C18.4604 11.5345 18.4993 11.3886 18.4993 11.2401C18.4993 11.0916 18.4604 10.9457 18.3865 10.8169C18.3126 10.6881 18.2062 10.581 18.078 10.5061L3.38 1.91608ZM0 2.99608C0 0.872077 2.304 -0.449923 4.138 0.620077L18.835 9.21008C20.387 10.1171 20.387 12.3601 18.835 13.2681L4.138 21.8581C2.304 22.9301 0 21.6071 0 19.4841V2.99608Z" fill="black"/>
                        </svg></span>
                    </button>}
                  light={_src}

                /> 
              }
            </div>
          </div>
          <div className="hero_item__content">&nbsp;</div>
        </div>
      )
    }
  }

  const scrollTo = (e:any, eleId:string) => {
    e.preventDefault();
    
    if (typeof window !== "undefined") {
      const _elm = document.getElementById(eleId);
      const _header = document.getElementById('header')

      if (_elm && _header) {

        setTimeout(() => {
          const topOfElement = window.pageYOffset + _elm.getBoundingClientRect().top - _header.offsetHeight - 25;
          window.scroll({ top: topOfElement, behavior: "smooth" });
        }, 100)
       
      }
    
    }

  }

  return (
    <HeroWrap>
      <div className="inner">
        <SliderComponent
          id="header-slider" 
          nav={true}
          isFade={true}
          initialSlide={activeSlide}
          playSpeed={playSpeed}
          slidesPerView={1}
          isAutoPlay={true}
        >

          { contents && contents.map((content: any, key:number) => {

            const { color } = content;
            const { background_color } = content;
            let bgColor = background_color;
            let textColor = color;

            if (!bgColor) {
              switch (color) {

                case '#fff':
                case '#ffffff':
                  bgColor = '#000';
                  break;
  
                case '#000':
                case '#000000':
                  bgColor = '#efe5e4';
                  break;
  
                default:
                  bgColor = hexToRGB(color, 0.2);
                  break;
              }
            }


            return (
              <SwiperSlide key={key}>
                {({ isActive }) => (
                  <div className={`hero_item ${content.invert ? 'is-alternate' : ''} ${content.is_video ? 'is-video' : ''}`}>
                    { !content.is_video && SlideImage(content, textColor, bgColor ) }
                    { content.is_video && SlideVideo(content, isActive) }
                  </div>
                )}
              </SwiperSlide>
            )
          })}
          
        </SliderComponent>

        <div className="scrollDown hidden-mobile">
            <a href="#" className="" onClick={(e) => scrollTo(e,'content')}> 
              <span className="scrollDown__text">scroll</span>
              <span className="scrollDown__icon">
                <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.9362 11.6754L7.84373 17.7678L6.13785 17.7678L0.0454102 11.6754L1.75129 9.94513L5.7723 13.9418L5.7723 0.367823L8.20928 0.367823L8.20928 13.9418L12.2303 9.92077L13.9362 11.6754Z" fill="black"  />
                  </svg>
              </span>
            </a>
        </div>

      </div>
    </HeroWrap>
  )
}

export default HeroSlider;

const HeroWrap = styled.div`
  padding: 0 20px;
  position: relative;
  max-width: 420px;
  margin: 0 auto;

  .swiper-slide {
    /* min-height: 50vh; */
    pointer-events: none;
    transition-property: opacity;

    &.swiper-slide-active {
      pointer-events: auto;
    }
  }

  .hero {
    &_item {
      /* background: rgba(242, 242, 242, 0.64); */
      background-color: #fff;
      border-radius: 600px;
      overflow: hidden;
      /* touch-action: none; */
      box-sizing: border-box;
      position: relative;
      z-index: 1000;
      /* min-height: 50vh; */

      &__wrap {
        display: flex;
        flex-direction: column;
        position: relative;
      }

      h2 {
        font-weight: 700;
        font-size: 40px;
        text-transform: uppercase;
        margin-bottom: 25px;
        letter-spacing: 0.1em;
        margin-top: 0px;

        span {
          display: block;
        }
      }


      &__desc {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
      }

      &__more {
        display: none;
      }

      &__content {
        min-height: 427px;
        padding: 100px 45px 30px;
        position: relative;
        z-index: 20;
      }

      &__picture {
        position: relative;
        z-index: 10;

        &__wrap {
          height: 427px;
          position: relative;
          width: 100%;
        }
      }


      &.is-alternate {
        .hero_item {

          &__wrap {
            flex-direction: column-reverse;
          }
          
          &__content {
            padding-top: 30px;
            padding-bottom: 100px;
          }
        }
      }


      // video

      &.is-video {
        background-color: #000;

        &__wrap {
          &.has-video {
            .react-player {
              padding-top: 56.25%;

            }
          }
        }

        .hero_item {
          &__content {
            height: 427px;
            width: 100%;
          }

          &__picture {
            height: 427px;
            width: 100%;
          }
        } 
      }

      

    }
  }

  .scrollDown {
    position: absolute;
    z-index: 888;

    a {
      display: inline-block;
      position: relative;
      height: 60px;
      padding-bottom: 30px;

      span {
        display: block;
      }

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
        .scrollDown__icon {
          transform: translate(0, 10px);
        }
      }
    }

    &__text {
      transform: rotate(-90deg);
      display: block;
      width: 50px;
      height: 20px;
    }

    &__icon {
      width: 14px;
      height: 18px;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -7px;
      transition: all 600ms;
      
      svg {
        width: 100%;
        height: auto;
      }
    }

  }

  .player-wrapper {
    width: auto; // Reset width
    height: auto; // Reset height
  }
  .react-player {
    padding-top: 56.25%; // Percentage ratio for 16:9
    position: relative; // Set to relative
  }

  .react-player > div {
    position: absolute; // Scaling will occur since parent is relative now
    top: auto;
    left: 0;
    bottom: -90px;
    transform: scale(2.2);
  }

  .sliderWrap__navigation {
    display: block;
    position: absolute;
    top: 41%;
    z-index: 999;
    right: 5%;

    &_wrap {
      display: flex;
      > div {
        display: flex;
        width: 40px;
        height: 40px;
        cursor: pointer;

        svg {
          margin: auto;

          path {
            fill: #fff;
          }
        }

        &.swiper-button-disabled {
          display: none;
        }

        &:hover {
          svg {
            path {
              fill: #000;
            }
          }
        }
      }
    }
  }

  .btnPlay {
    border: 0;
    background: #fff;
    border-radius: 90px;
    width: 84px;
    height: 45px;
    display: block;
    overflow: hidden;
    transform: scale(0.45);
    cursor: pointer;
    
    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    svg {
      width: 20px;
      height: 23px;
      display: block;
    }

    &:hover,
    &:focus {
      background-color: #000;

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }


  @media screen and (min-width: ${({theme}) => theme.breakPoints.tablet}) {
    width: 50%;
    max-width: none;
    margin: 0 auto;
  }

  @media screen and (min-width: ${({theme}) => theme.breakPoints.desktop}) {
    width: 100%;
    min-height: 50vh;

    .hero {
      &_item {

        &__wrap {
          flex-direction: row;
        }

        &__more {
          display: block;
          text-align: right;
          position: absolute;
          bottom: 20px;
          right: calc(50% + 35px);
          z-index: 300;
          cursor: pointer;

          a {
            font-size: 14px;
            font-weight: 700;

            span {
              display: block;
              margin-bottom: 5px;
            }

            svg {
              display: block;
              margin: auto;
              width: 13px;
              transform: rotate(90deg);
            }
          }
        }

        &__content {
          width: 50%;
          min-height: auto;
          display: flex;
          padding-top: 45px;
          padding-bottom: 45px;
          flex-direction: column;
          justify-content: center;
          padding-left: 80px;
        }

        &__picture {
          width: 50%;
          min-height: auto;
        }


        &.is-alternate {
          .hero_item {
            &__wrap {
              flex-direction: row-reverse;
            }

            &__more {
              left: calc(50% + 35px);
              right: auto;
            }

            &__content {
              padding-bottom: 45px;
              padding-top: 45px;
            }
          }
        }

        &.is-video {
          .hero_item {
            &__content {
              display: none;
            }

            &__picture {
              width: 100%;
            }
          }
        }


        &__wrap {
          &.has-video {
            height: 427px;
            display: block;

            .react-player {
              padding-top: 56.25%;
              > div {
                top: 0;
                transform: scale(1);
              }
            
            }
          }
        }
      }
    }

    .btnPlay {
      transform: scale(1) translate(0, -10vh);
    }

    .scrollDown {
      width: 60px;
      left: 50%;
      margin-left: 180px;
      transform: translate(0, -40px);
      bottom: 0;
    }

    .sliderWrap__navigation {
      display: none;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      position: static;
    }

    .sliderWrap__pagination {
      min-height: 70px;

      .swiper-pagination {
        transform: translate(0, 0);
        bottom: 0;
      }
    }
  }

  @media screen and (min-width: ${({theme}) => theme.breakPoints.wide}) {
    padding: 0 40px;

    .hero {
      &_item {

        h2 {
          font-size: 60px;
          font-size: 4.166vw;
        }
        
        &__more {
          bottom: 2.083vw;
          right: calc(50% + 4.861vw);

          a {
            font-size: 0.972vw;

            span {
              margin-bottom: 0.347vw;
            }

            svg {
              width: 0.902vw;
            }
          }
        }

        &__content {
          padding-left: 14vw;
        }

        &__desc {
          font-size: 18px;
          font-size: 1.25vw;
          padding-left: 4vw;
          padding-right: 2vw;
        }

        &__picture {
          &__wrap {
            height: 536px;
            height: 37.22vw;
          }
        }


        &.is-alternate {
          .hero_item {

            &__more {
              left: calc(50% + 4.861vw);
              right: auto;
            }

            &__content {
              padding-left: 10vw;
              padding-right: 4vw;
            }
          }
        }

        &.is-video {}


        &__wrap {
          &.has-video {
            height: 536px;
            height: 37.22vw;
            .react-player {
              /* padding-top: 39.25%; */
            }
          }
        }

      }
    }

    .btnPlay {
      transform: scale(1) translate(0, -15vh);
    }

    
  }
`