import React, { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper';
import { Swiper } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

interface Props {
  children: JSX.Element| JSX.Element[];
  nav: boolean;
  id: string;
  isFade: boolean;
  initialSlide?: number;
  playSpeed: number;
  slidesPerView?: number;
  isAutoPlay: boolean;
  // eslint-disable-next-line no-unused-vars
  slideChangeHandle?: (swiper: any) => void;
}

const SliderComponent = ({id, children, nav, initialSlide=0, playSpeed=300, isFade, slidesPerView, isAutoPlay, slideChangeHandle }: Props) => {

  const sliderElm = useRef<HTMLDivElement>(null)
  
  const slideOpt = useMemo(() => {
    return {
      speed: playSpeed,
      spaceBetween: 0,
      initialSlide,
      pagination: { 
        clickable: true,
        el: '',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      autoHeight: true,
      edgeSwipeDetection: true,
      edgeSwipeThreshold: 40,
    }
    
  },[initialSlide, playSpeed]);

  useEffect(() => {
    if (sliderElm.current) {
      slideOpt.pagination.el = '.' + id + '-swiper-pagination'
    }

    if (nav && sliderElm.current) {
      slideOpt.navigation.nextEl = '.' + id + '-swiper-next';
      slideOpt.navigation.prevEl = '.' + id + '-swiper-prev';
    }
    
  },[nav, slideOpt, id])

  useEffect(() => {
    
  },[])

  return (
    <SliderComponentWrap id={id} ref={sliderElm}>

      <div className="sliderWrap" >
        <Swiper
          slidesPerView={slidesPerView ? slidesPerView : 'auto'}
          centeredSlides={slidesPerView === 1 ? true : false}
          modules={[Navigation, Pagination, EffectFade, Autoplay ]}
          {...slideOpt}
          autoplay={ isAutoPlay ? {
            delay: 6000,
            pauseOnMouseEnter: true
          } : false}
        
          effect={
            isFade ? 'fade' : 'slide'
          }

          onSlideChange={(swiper) => slideChangeHandle? slideChangeHandle(swiper) : null }

        >
          { children }
        </Swiper>



        <div className="sliderWrap__pagination">
          <div className={`swiper-pagination ${id}-swiper-pagination`}></div>
        </div>

        {nav && <div className={`sliderWrap__navigation`}>

            <div className="sliderWrap__navigation_wrap">
              <div className={`swiper-prev ${id}-swiper-prev`}>
                <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.5625 9.38896L0.5 5.32646L0.5 4.18896L4.5625 0.126465L5.71625 1.26396L3.05125 3.94521L12.1025 3.94521V5.57022L3.05125 5.57022L5.7325 8.25146L4.5625 9.38896Z" fill="black"/>
                </svg>
              </div>
              <div className={`swiper-next ${id}-swiper-next`}>
                <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.27539 0.613966L12.3379 4.67647L12.3379 5.81397L8.27539 9.87647L7.12164 8.73897L9.78664 6.05772L0.735389 6.05772L0.735389 4.43272L9.78664 4.43271L7.10539 1.75147L8.27539 0.613966Z" fill="black"/>
                </svg>
              </div>

            </div>
        </div>}

      </div>  

      
    </SliderComponentWrap>
  )
}

export default SliderComponent;


const SliderComponentWrap = styled.div`
  padding-bottom: 80px;

  .swiper {
    &-pagination {
      bottom: -60px;
      
      &-bullet {
        width: 12px;
        height: 12px;
        background-color: transparent;
        border: 1px solid #000;
        opacity: 1;
        margin: 0 6px !important;
        transition: all 500ms;

        &-active {
          width: 38px;
          background-color: #000;
          border-radius: 20px;
        }


        &:hover {
          background-color: #000;
        }
      }
    }
  }

  .sliderWrap {
    &__pagination {
      position: relative;
    }
  }


  @media screen and (min-width: ${({theme}) => theme.breakPoints.desktop}) {
    // padding-bottom: 120px;
    .sliderWrap {
      position: relative;

      &__navigation {
        position: absolute;
        z-index: 100;

        &_wrap {
          display: flex;
        }

        .swiper {
          &-prev,
          &-next {
            background-color: #fff;
            min-width: 47px;
            display: flex;
            justify-content: center;
            padding: 12px 5px;
            box-sizing: border-box;
            cursor: pointer;
            transition: all 600ms;

            &:hover {
              background-color: #000;
              svg path {
                fill: #fff;
              }
            }
          }

          &-prev {
            border-radius: 40px 0 0 40px;
            padding-right: 5px;
          }

          &-next {
            border-radius: 0 40px 40px 0;
            padding-left: 5px;
          }
        }

      
      }

    }

  }


   @media screen and (min-width: ${({theme}) => theme.breakPoints.wide }) {
    .sliderWrap {
      &__navigation {
        right: 6.4vw;
      }
    }
  }

  @media screen and (min-width: ${({theme}) => theme.breakPoints.hd }) {
    .sliderWrap {
      &__navigation {
        right: 7.4vw;
      }
    }
  }

  
`

